<template>
    <div>
        <Toast />
        <div class="card">
            <div class="p-grid p-fluid">
                <div class="p-col-12">
                    <h5>{{ $t('ModellUser.search.searchHeading') }}</h5>
                    <div class="p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-3">
                            <label for="type">{{ $t('ModellUser.search.Name') }}</label>
                            <InputText id="googlurl" type="text" :placeholder="$t('ModellUser.search.SBName')"
                                v-model="name" />
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="type">{{ $t('ModellUser.search.Email') }}</label>
                            <InputText id="googlurl" type="text" :placeholder="$t('ModellUser.search.SBEmail')"
                                v-model="email" />
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="type">{{ $t('ModellUser.search.Mobile') }}</label>
                            <InputText id="googlurl" type="text" :placeholder="$t('ModellUser.search.SBPhone')"
                                v-model="mobile" onkeypress="return /[0-9]/i.test(event.key)" />
                        </div>

                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{ $t('ModellUser.search.Country') }}</label>
                            <Dropdown v-model="country_id" :options="dropdownCountryValues" optionValue="id"
                                optionLabel="country_name" :placeholder="$t('ModellUser.search.Select')" />
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{ $t('ModellUser.search.AgeFrom') }}</label>
                            <InputText id="googlurl" type="text" :placeholder="$t('ModellUser.search.SBAgeFrom')" v-model="age_from"
                                onkeypress="return /[0-9]/i.test(event.key)" />
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{ $t('ModellUser.search.AgeTo') }}</label>
                            <InputText id="googlurl" type="text" :placeholder="$t('ModellUser.search.SBAgeTo')" v-model="age_to"
                                onkeypress="return /[0-9]/i.test(event.key)" />
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{ $t('ModellUser.search.Gender') }}</label>
                            <Dropdown v-model="gender" :options="genderDropDowns" optionValue="value" optionLabel="name"
                                :placeholder="$t('ModellUser.search.Select')" />
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{ $t('ModellUser.search.Status') }}</label>
                            <Dropdown v-model="status" :options="dropdownstatusValues" optionValue="value"
                                optionLabel="name" :placeholder="$t('ModellUser.search.Select')" />
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{ $t('ModellUser.search.RegistrationDate') }}</label>
                            <Calendar inputId="range" v-model="dateRange" selectionMode="range" :manualInput="false"
                                placeholder="YYYY/MM/DD - YYYY/MM/DD" :showIcon="true"></Calendar>
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{ $t('ModellUser.search.ApprovalDate') }}</label>
                            <Calendar inputId="range" v-model="approveRange" selectionMode="range" :manualInput="false"
                                placeholder="YYYY/MM/DD - YYYY/MM/DD" :showIcon="true"></Calendar>

                        </div>

                    </div>
                </div>
            </div>
            <div class="p-d-flex p-jc-between p-ai-lg-center p-ai-start p-mt-6 p-flex-column p-flex-lg-row">
                <div class="p-mb-4 p-mb-lg-0"></div>
                <div>
                    <Button :label="$t('button.search')" icon="pi pi-search" iconPos="left"
                        class="p-button p-button-sm p-mr-2 p-mb-2" @click="searchModelUser()"></Button>
                    <Button :label="$t('button.reset')" icon="pi pi-replay" iconPos="left"
                        class="p-button p-button-sm p-mr-2 p-mb-2" @click="ResetModelUser()"></Button>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="p-grid">
                <div class="p-col-12">
                    <div class="p-d-flex p-jc-between p-mb-2">
                        <h4>{{ $t('ModellUser.list.Heading') }}</h4>
                        <div>
                            <!-- <router-link to="/AddFreeBoard">
                                <Button label="Add New" icon="pi pi-plus" iconPos="left"
                                    class="p-button p-button-sm p-mr-2 p-mb-2"></Button>
                            </router-link> -->
                        </div>
                    </div>
                    <DataTable :value="ModelUsers" :paginator="true" class="p-datatable-gridlines" :rows="10"
                        dataKey="id" :rowHover="true" :loading="loading1" :filters="filters1" responsiveLayout="scroll">
                        <ConfirmDialog group="dialog" />

                        <template #empty>{{$t('Nodata')}}</template>
                        <template #loading>Loading data. wait.</template>

                        <Column field="#" header="#" style="min-width: 5rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Sl. No.</span>
                                {{ data.sl_no }}
                            </template>
                        </Column>
                        <Column field="Name" :header="$t('ModellUser.list.Name')" style="min-width: 13rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Name</span>
                                {{ data.name === null ? '--' : data.name }}
                            </template>
                        </Column>
                        <Column field="Age" :header="$t('ModellUser.list.Age')" style="min-width: 13rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Age</span>
                                {{ data.age === null ? '--' : data.age }}
                            </template>
                        </Column>
                        <Column field="Gender" :header="$t('ModellUser.list.Gender')" style="min-width: 13rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Gender</span>
                                <span v-if="data.gender === 'm'">Male</span>
                                <span v-else-if="data.gender === 'f'">Female</span>
                                <span v-else>--</span>
                                <!-- {{ data.gender }} -->
                            </template>
                        </Column>
                        <Column field="Email" :header="$t('ModellUser.list.Email')" style="min-width: 13rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Email</span>
                                {{ data.email === null ? '--' : data.email }}
                            </template>
                        </Column>
                        <Column field="Mobile Number" :header="$t('ModellUser.list.MobileNumber')"
                            style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Mobile Number</span>
                                {{ data.country_code === null ? '' : "+" + data.country_code + " - " }} {{
                                    data.mobile
                                        === null ? '--' : data.mobile
                                }}

                            </template>
                        </Column>
                        <Column field="Height" :header="$t('ModellUser.list.Height')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Height</span>
                                {{ data.height === null ? '--' : data.height }}
                            </template>
                        </Column>
                        <Column field="Weight" :header="$t('ModellUser.list.Weight')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Weight</span>
                                {{ data.weight === null ? '--' : data.weight }}
                            </template>
                        </Column>
                        <Column field="Country" :header="$t('ModellUser.list.Country')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Country</span>
                                {{ data.country_name }}
                            </template>
                        </Column>
                        <Column field="Status" :header="$t('ModellUser.list.Status')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <!-- <span class="p-column-title">Status</span>
                                {{ data.status }} -->
                                <span v-if="data.status === 'active'">Active</span>
                                <span v-else-if="data.status === 'deleted'">InActive</span>
                                <span v-else>Declined</span>
                            </template>
                        </Column>
                        <Column field="Registration Date" :header="$t('ModellUser.list.RegistrationDate')"
                            style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Registration Date</span>

                                {{ FormatDate(data.registration_date) }}
                            </template>
                        </Column>
                        <Column :header="$t('ModellUser.list.ApplicationStatus')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Application Status</span>
                                <InputSwitch v-model="data.is_admin_approved" trueValue="1"
                                    @change="switchValue(data.id, data.is_admin_approved)" />
                                <!-- {{ data.status }} -->
                            </template>
                        </Column>
                        <Column field="Approval Date" :header="$t('ModellUser.list.ApprovalDate')"
                            style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Approval Date</span>

                                {{ data.admin_approval_date === null ? '---' : FormatDate(data.admin_approval_date) }}
                            </template>
                        </Column>

                        <Column field="Actions" :header="$t('ModellUser.list.Action')">
                            <template #body="{ data }">
                                <span class="p-column-title">Actions</span>
                                <p style="display: none">{{ data.status }}</p>
                                <div style="display: flex">
                                    <router-link :to="'/ModelUser/ViewModelUser/' + data.id">
                                        <Button label="info" class="n-wrap p-button-outlined p-button-info p-mb-2">
                                            <i class="pi pi-eye p-mr-2"></i>
                                        </Button>
                                    </router-link>
                                    <!-- <router-link to="">
                                        <Button label="info"
                                            class="n-wrap p-button-outlined p-button-info p-mr-2 p-mb-2">
                                            <i class="pi pi-pencil p-mr-2"></i>
                                        </Button>
                                    </router-link> -->
                                    <Button icon="pi pi-trash" class="n-wrap p-button-danger p-button-outlined p-mb-2"
                                        @click="deleteNote(data.id)" />

                                    <!-- <Button :label="$t('button.delete')" icon="pi pi-trash" class="n-wrap p-button-danger p-button-outlined p-mr-2 p-mb-2" @click="confirm(data.id)" /> -->
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useRoute } from 'vue-router';
import ModelUserService from '../../../service/API/SuperAdmin/ModelUserService.js';
import axios from 'axios';
import moment from 'moment';
export default {
    data() {
        return {
            dropdownstatusValues: [
                { name: 'Active', value: 'active' },
                { name: 'Inactive', value: 'inactive' },
                // { name: 'Declined', value: 'declined' },
            ],
            serial: 0,
            dropdownstatusValue: null,
            age_groups: [
                { name: '25~35', value: '25 ~ 35' },
                { name: '35~45', value: '35 ~ 45' },
                { name: '45~55', value: '45 ~ 55' },
                { name: '55~65', value: '55 ~ 65' },
            ],
            ageDropDowns: null,
            genderDropDowns: [
                { name: 'Male', value: 'm' },
                { name: 'Female', value: 'f' },
            ],
            genderDropDownvalue: null,
            isModalVisible: false,
            dropdownCountryValues: '',
            dropdownCountryValue: null,
            dropdownBoardCategoryValues: '',
            dropdownBoardCategoryValue: null,
            FreeBoards: null,
            loading1: true,
            deletedID: null,
            sl_no: '',
            title: '',
            country_id: '',
            category_id: '',
            createdDate: '',
            lastDate: '',
            status: '',
            total: '',
            id: '',
            description: '',
            posted_by: '',
            posted_date: '',
            name: '',
            email: '',
            mobile: '',
            gender: '',
            from_date: '',
            to_date: '',
            age_from: '',
            age_to: '',
            ModelUsers: [],
            registration_date: '',
            dateRange: '',
            is_admin_approved: '',
            approval_date_from: '',
            approval_date_to: '',
            approvedate: '',
            approveenddate: '',
            approveRange: ''
        };
    },
    created() {
        this.modelUserService = new ModelUserService();
    },
    mounted() {

        this.getMUserlist();
        const route = useRoute();
        console.log(route.params);
        this.modelUserService
            .getCountrydropdown()
            .then((data) => {
                this.dropdownCountryValues = data.data.data.country;
                // this.products = data;
                this.loading1 = false;
                // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));
                console.log(this.dropdownCountryValues);
            })
            .catch((err) => console.log(err));


        // this.bulletinCategoryManagementService
        // .getBoardCategorydropdown()
        // .then((data) => {
        //     this.dropdownBoardCategoryValues = data.data.data.board_category;
        //     // this.products = data;
        //     this.loading1 = false;
        //     // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));
        //     console.log(this.dropdownBoardCategoryValues);
        // })
        // .catch((err) => console.log(err));
    },
    methods: {
        getMUserlist() {
            const MUserParams = {
                // page: 1,
                // limit: this.pagination.pagination_limit,
                email: this.email,
                country_id: this.country_id,
                name: this.name,
                mobile: this.mobile,
                status: this.status,
                from_date: this.searchdate,
                to_date: this.searchenddate,
                gender: this.gender,
                age_from: this.age_from,
                age_to: this.age_to,
                approval_date_from: this.approvedate,
                approval_date_to: this.approveenddate
            };
            this.loading1 = true;
            this.modelUserService
                .getModellList(MUserParams)
                .then((res) => {
                    this.ModelUsers = res.data.data.users;
                    this.totalrecords = res.data.total_records;
                    // this.setPagination(res.data.current_page, res.data.total_pages === 0 ? 1 : res.data.total_pages, res.data.total_records);
                    this.loading1 = false;
                    console.log(res);
                })
                .catch((err) => console.log(err));
        },
        switchValue(ids, switchstatus) {
            console.log(ids, switchstatus);
            var self = this;
            axios({ method: 'put', url: 'asnhist-dev/api/v1/admin/user/model/change-application-status', data: { id: ids, application_status: switchstatus === false ? '0' : '1' } }).then(function (response) {
                // this.$router.push({ name: 'Banner Management' });
                self.$toast.add({ severity: 'info', summary: '확인.', detail: '지원서 상태가 성공적으로 변경되었습니다.', life: 3000 });
                console.log(response);
            });
        },
        addDay(val) {
            const date = new Date(val);
            var dd = date.getDate();
            var mm = date.getMonth() + 1;
            var yyyy = date.getFullYear();
            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            return (val = yyyy + '-' + mm + '-' + dd);
        },

        ResetModelUser() {
            this.name = '';
            this.country_id = '';
            this.email = '';
            this.dateRange = '';
            this.mobile = '',
                this.age_from = '',
                this.age_to = '',
                this.status = '',
                this.gender = '',
                this.approveRange = '',
                this.from_date = '',
                this.to_date = '',
                this.approval_date_from = '',
                this.approval_date_to = '',
                this.loading1 = true;

            this.getMUserlist()
        },


        searchModelUser() {
            if (this.name === '' && this.country_id === '' && this.email === '' && this.mobile === '' && this.gender === '' && this.status === '' && this.dateRange === '' && this.age_from === '' && this.age_to === '' && this.approveRange === '') {
                this.$toast.add({ severity: 'error', summary: '에러 메시지', detail: '검색창을 입력해주세요.', life: 2000 });
            } else {
                if (this.dateRange.at(0) != '') {
                    this.searchdate = this.formatRangeDate(this.dateRange.at(0));
                } else {
                    this.searchdate = '';
                }
                if (this.dateRange.at(1) != '') {
                    this.searchenddate = this.formatRangeDate(this.dateRange.at(1));
                } else {
                    this.searchenddate = '';
                }
                if (this.approveRange.at(0) != '') {
                    this.approvedate = this.formatRangeDate(this.approveRange.at(0));
                } else {
                    this.approvedate = '';
                }
                if (this.approveRange.at(1) != '') {
                    this.approveenddate = this.formatRangeDate(this.approveRange.at(1));
                } else {
                    this.approveenddate = '';
                }
                const ModelUserParams = {
                    // page: 1,
                    // limit: this.pagination.pagination_limit,
                    email: this.email,
                    country_id: this.country_id,
                    name: this.name,
                    mobile: this.mobile,
                    status: this.status,
                    from_date: this.searchdate,
                    to_date: this.searchenddate,
                    gender: this.gender,
                    age_from: this.age_from,
                    age_to: this.age_to,
                    approval_date_from: this.approvedate,
                    approval_date_to: this.approveenddate
                };
                this.loading1 = true;
                this.modelUserService
                    .getModellList(ModelUserParams)
                    .then((res) => {
                        this.ModelUsers = res.data.data.users;
                        // this.total = res.data.data.total;
                        this.loading1 = false;
                        console.log(res);
                    })
                    .catch(() => {
                        this.ModelUsers = [];
                        this.loading1 = false;
                    });
            }
        },

        dateformat(value) {
            if (value) {
                return moment(String(value)).locale('ko').format('ll - LTS');
            }
        },

        FormatDate(value) {
            if (value) {
                // return moment(String(value)).format('YYYY/MM/DD ');
                return moment(String(value)).locale('ko').format('ll');
            }
        },
        formatRangeDate(date) {
            if (date === undefined || date === '') {
                return '';
            }
            return moment(new Date(date)).format('YYYY-MM-DD');
        },

        deleteNote: function (id) {
            console.log(id);
            var self = this;
            this.$confirm.require({
                group: 'dialog',
                header: '삭제',
                message: '정말로 삭제하시겠습니까?',
                icon: 'pi pi-trash',
                acceptLabel: "삭제",
                rejectLabel: "취소",
                accept: () => {
                    axios({ method: 'delete', url: '/asnhist-dev/api/v1/admin/user/delete', data: { deleteIdArray: id } }).then(function (response) {
                        console.log(response);
                        self.getMUserlist()
                        // self.managerService
                        //     .getManagerList(self.name, self.email, self.mobile, self.country_id, self.status)
                        //     .then((res) => {
                        //         self.products = res.data.data.manager;
                        //         //self.total = res.data.data.total;
                        //         self.loading1 = false;
                        //         // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));

                        //         console.log(res);
                        //     })
                        //     .catch((err) => console.log(err));

                        // let i = this.products.map(data => data.id).indexOf(id);

                        // this.products.splice(i, 1)
                    });
                    this.$toast.add({ severity: 'info', summary: '삭제되었습니다.', detail: '성공적으로 삭제되었습니다.', life: 3000 });
                },
                // reject: () => {
                //     this.$toast.add({ severity: 'error', summary: 'An error occurred', detail: 'You have rejected', life: 3000 });
                // },

            });

        },

    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}

::v-deep(.p-progressbar) {
    height: 0.5rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #607d8b;
    }
}

::v-deep(.p-datatable .p-sortable-column .p-column-title) {
    display: block;
}

.p-datatable .p-column-filter {
    display: none;
}

.table-header {
    display: flex;
    justify-content: space-between;
}

.customer-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-qualified {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-unqualified {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-negotiation {
        background: #feedaf;
        color: #8a5340;
    }

    &.status-new {
        background: #b3e5fc;
        color: #23547b;
    }

    &.status-renewal {
        background: #eccfff;
        color: #694382;
    }

    &.status-proposal {
        background: #ffd8b2;
        color: #805b36;
    }
}

.p-progressbar-value.ui-widget-header {
    background: #607d8b;
}

@media (max-width: 640px) {
    .p-progressbar {
        margin-top: 0.5rem;
    }
}

.product-image {
    width: 100px;
    height: 50px;
    // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.orders-subtable {
    padding: 1rem;
}

.product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-instock {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-outofstock {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-lowstock {
        background: #feedaf;
        color: #8a5340;
    }
}

.order-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.order-delivered {
        background: #c8e6c9;
        color: #256029;
    }

    &.order-cancelled {
        background: #ffcdd2;
        color: #c63737;
    }

    &.order-pending {
        background: #feedaf;
        color: #8a5340;
    }

    &.order-returned {
        background: #eccfff;
        color: #694382;
    }
}

.p-datatable {
    .p-sortable-column {
        &.p-highlight {
            &:hover {
                background: rgb(216 217 243);
                color: #464df2;
            }
        }
    }
}

.true-icon {
    color: #256029;
}

.false-icon {
    color: #c63737;
}

.w-100 {
    width: 100%;
}

.p-button {
    background: #000000;
    border: 1px solid #0a0a0a;
}

.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info>.p-button.p-button-outlined,
.p-splitbutton.p-button-info>.p-button.p-button-outlined {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-info.p-button-outlined:hover,
.p-buttonset.p-button-info>.p-button.p-button-outlined,
.p-splitbutton.p-button-info>.p-button.p-button-outlined:hover {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:active,
.p-buttonset.p-button-info>.p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-info>.p-button.p-button-outlined:enabled:active {
    background: rgba(2, 136, 209, 0.16);
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help>.p-button.p-button-outlined,
.p-splitbutton.p-button-help>.p-button.p-button-outlined {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-help.p-button-outlined:hover,
.p-buttonset.p-button-help>.p-button.p-button-outlined,
.p-splitbutton.p-button-help>.p-button.p-button-outlined:hover {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger>.p-button.p-button-outlined,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-danger.p-button-outlined:hover,
.p-buttonset.p-button-danger>.p-button.p-button-outlined,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined:hover {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}
</style>